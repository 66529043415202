import React from "react"
import Layout from "../../components/layout-ages"
import { Link } from "gatsby"

export default () => (
  <Layout title="8-10 Year Old">
    <h2>8-10 Year Old</h2>
    <div className="clear-block">
      <div id="node-18" className="node">


        <div className="content clear-block">
          <p><img src="/images/214-230x307.JPG" className="float-left" width="230"
                  height="307" alt=""/> Music teachers, I know you are convinced the method you are now using is the best on
            the market. You are used to using it and it would take effort to switch to another method. As I have taught
            many years I understand your position, but let’s seriously examine our goal. Isn’t it to give our students a
            strong foundation in note reading and timing first and foremost. How many times have you had a student
            struggle with reading the notes and stumble with timing because they can’t figure out the next note. Have
            you had them quit because they just don’t like music. I certainly did and the Animal Note method was my
            solution to this problem. Because it is based on associative learning and has word clues to help remember
            the notes location on the Grand Staff and keyboard, it is easy to learn and transfer the knowledge to
            standard music notation. The “<Link to="/books/Beginning-Timing">Timing for Children</Link>” in the Animal Notes
            so the student can easily read the music and concentrate of the second important element of music, getting
            the notes in the correct proportion to each other.</p>
          <p>Starting your young student in the Animal Note method means success for you. It is different, I will not
            deny that, but it works. Different is not bad, it is just different. Try it with your next beginning
            student, I think you will be pleasantly surprised. </p>
          <p>Parents, if you are searching for a method that will lead to your child will succeed in piano, please
            suggest this to the child's teacher, or teach your child yourself. This method is written so anyone,
            (whether they have had piano themselves of not,) will be successful when start the child in their life to
            play piano and love music. Check out, you will be delighted you did.</p>
          <h3>Testimonial</h3>
          <p><i>"I love note-i-mals because after you learn the animals you can play anything!"<br/>
            - Richard - age 8</i></p>
        </div>

        <div className="clear-block">
          <div className="meta">
          </div>

        </div>

      </div>
    </div>
  </Layout>
)
